import React from "react";

import {Button, Container, Row, Col, NavDropdown} from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import Logo from "../../assets/images/logo-AMCEmmett.webp";
import imgPhone from "../../assets/images/icons/phone.svg";
import imgEmail from "../../assets/images/icons/envelope.svg";

import "./header.scss";

const Header = () => {
    return (
        <>
            <Container fluid className="header-contact-info">
                <Row>
                    <Col sm={6}>
                        <h6><img src={imgPhone} alt="" /> <a href="tel:208-365-5396">(208) 365-5396</a> </h6>
                    </Col>
                    <Col sm={6}>
                        <h6> <img src={imgEmail} alt="" /> <a href={`mailto: ${process.env.REACT_APP_DISPLAY_EMAIL}`}>{process.env.REACT_APP_DISPLAY_EMAIL}</a></h6>
                    </Col>
                </Row>
                 
            </Container>
            <Navbar  expand="lg">
                <Container>
                    <Navbar.Brand href="/"><img className="header-logo" src={Logo} alt={"logo"}/><div className="header-logo-text">Animal Medical Center <br></br>of Emmett</div></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="justify-content-end">
                            <Nav.Link href="/about-us">About Us</Nav.Link>
                            <NavDropdown title="Resources" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/new-patient">New Patient Form</NavDropdown.Item>
                                <NavDropdown.Item href="/financing/scratchpay">Scratchpay</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/services">Services</Nav.Link>
                            <Nav.Link href="/contact-us">Contact</Nav.Link>
                            <div>
                                <Button 
                                    variant="primary" 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    href="https://animalmedicalcenteremmett.vetsfirstchoice.com/" 
                                    className="header-button">Online Pharmacy</Button>
                            </div>
                            
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;