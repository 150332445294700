import imgShelbyPattee from "../../assets/images/about_us/Pattee_Shelby.webp";
import imgBrittanyDianda from "../../assets/images/about_us/brittany_dianda.webp";
import imgCyndaSmith from "../../assets/images/about_us/cynda_smith.webp";
import imgAndreaDoty from "../../assets/images/about_us/andrea_doty.webp";
import imgHeatherBrewer from "../../assets/images/about_us/heather_brewer.webp";
import imgKatieGoff from "../../assets/images/about_us/katie_goff.webp";
import imgKayleeHumphries from "../../assets/images/about_us/kaylee_humphries.webp";
import imgKelsieSanders from "../../assets/images/about_us/kelsie_sanders.webp";
import imgLacyYates from "../../assets/images/about_us/lacy_yates.webp";
import imgLindsayFisher from "../../assets/images/about_us/lindsay_fisher.webp";
import imgLindsayGloeckner from "../../assets/images/about_us/lindsay_gloeckner.webp";
import imgTamaraMiller from "../../assets/images/about_us/tamara_miller.webp";
import imgTaraHuett from "../../assets/images/about_us/tarrah_huett.webp";
import imgPeterSkurdal from "../../assets/images/about_us/peter_skurdal.webp";
import imgJeanineHendrickson from "../../assets/images/about_us/jeanine_hendrickson.webp";
import imgKylieKerner from "../../assets/images/about_us/kylie_kerner.webp";
import imgBartGillespie from "../../assets/images/about_us/bart_gillespie.webp";
import imgKaitlynFox from "../../assets/images/about_us/kaitlyn_fox.webp";
import imgMikalaFrancke from "../../assets/images/about_us/mikala_francke.webp";
import imgCarleeHeindel from "../../assets/images/about_us/carlee_heindel.webp";
import imgKourtneyWalker from "../../assets/images/about_us/kourtney_walker.webp";
import imgDanDombroski from "../../assets/images/about_us/dan_dombroski.webp";
import imgDanaNeelis from "../../assets/images/about_us/dana_neelis.webp";
import imgRachelBrown from "../../assets/images/about_us/rachel_brown.webp";
import imgSophiaBorg from "../../assets/images/about_us/sophia_borg.webp";
// import imgPlaceholderMale from "../../assets/images/about_us/imageTeamMemberMale.webp";
// import imgPlaceholderFemale from "../../assets/images/about_us/imageTeamMemberFemale.webp";

const team = {
    data: [
        {
            id: "adfbe70ea62011eda8054422d5c0358d",
            staff_category_id: "ca361bf6a73811edafa10242ac120002",
            sequence: 1,
            image: imgDanDombroski,
            first_name: "Dan",
            last_name: "Dombroski",
            prefix: "Dr.",
            suffix: "",
            title: "",
            text: "Dr. Dombroski, or Dr. Dan as he is often called, graduated from Michigan State University College of Veterinary Medicine in 2006. After graduation, he joined Airport Animal Clinic in Michigan as an associate in dairy practice; however, he was soon drawn to the mountains and took a position as a small animal associate at Eastside Pet Clinic in Idaho Falls. In 2015, Dr. Dan purchased the Animal Medical Center and moved west to the Treasure Valley. Dr. Dan loves the challenge of practicing mixed animal veterinary care. He also has a passion for surgery, particularly orthopedic surgery. In his spare time, Dr. Dan loves hunting with his dog Eddie, being outdoors, and spending time with his family and friends.",
            sortOrderOverride: 1
        },
        {
            id: "abfbe70ea62011eda8054422d5c0358d",
            staff_category_id: "ca361bf6a73811edafa10242ac120002",
            sequence: 1,
            image: imgLindsayFisher,
            first_name: "Lindsay",
            last_name: "Fisher",
            prefix: "Dr.",
            suffix: "",
            title: "",
            text: "Dr. Lindsay Fisher is our main equine veterinarian at the practice but also sees small animals and small ruminants. Dr. Fisher was born and raised in Napa, California. She obtained a bachelor’s degree in Animal Science from the University of California, Davis. Upon graduation she spent one year working at the university as a technician in the large animal ultrasound department prior to veterinary school. In 2010 she graduated from the UC Davis Veterinary School and went straight to work in private practice as an equine practitioner. Shortly into her career, she purchased the equine division of the practice and established Napa Valley Equine. Prior to moving to Idaho in the fall of 2019, Dr. Fisher went to chiropractic school at Options for Animals in Kansas and offers this service to both horses and small animals. During her spare time she is kept busy with her two young children and actively competes in endurance rides on her horses.",
            sortOrderOverride: 2
        },
        {
            id: "acfbe70ea62011eda8054422d5c0358d",
            staff_category_id: "ca361bf6a73811edafa10242ac120002",
            sequence: 1,
            image: imgPeterSkurdal,
            first_name: "Peter",
            last_name: "Skurdal",
            prefix: "Dr.",
            suffix: "",
            title: "",
            text: "Dr. Peter Skurdal joined Animal Medical Center in January 2023. He is happy to be working with such a highly skilled team providing care to pets and livestock. Dr. Peter earned his DVM from Washington State University, and took his first job at a vet practice in Edendale, New Zealand. His work there ranged from dairy and sheep farms to working dogs, pets, and even some racing greyhounds. He moved back to the US and worked in a mobile food animal practice in western Oregon for almost 7 years. Outside of work, Dr. Peter enjoys cooking, hiking, and spending time with his wife, Heather, and two kids age 5 and 3.",
            sortOrderOverride: 3
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "ca361bf6a73811edafa10242ac120002",
            sequence: 1,
            image: imgDanaNeelis,
            first_name: "Dana",
            last_name: "Neelis",
            prefix: "Dr.",
            suffix: "DVM, DACVR, DACVR-EDI",
            title: "",
            text: "Dr. Neelis graduated from Michigan State University College of Veterinary Medicine in 2006. After veterinary school, she completed an equine internship at Rood and Riddle Equine Hospital in Lexington, KY and an ultrasound and cardiology fellowship at the University of Pennsylvania New Bolton Center. Dr. Neelis then completed a diagnostic imaging residency at Washington State University to become a board certified radiologist. In 2011, she became an assistant professor of radiology at Virginia-Maryland Regional College of Veterinary Medicine before joining Animal Imaging in Dallas, Texas. She currently provides imaging interpretations for veterinary practices in Michigan, Idaho and Texas and local ultrasound examinations in the Treasure Valley. She loves teaching and frequently lectures at veterinary continuing education courses and conferences. When she is not working, she loves spending time with her family, being outdoors with her dogs and reading a good book.",
            sortOrderOverride: 4
        },
        {
            id: "affbe70ea62011eda8054422d5c0358d",
            staff_category_id: "ca361bf6a73811edafa10242ac120002",
            sequence: 1,
            image: imgBartGillespie,
            first_name: "Bart",
            last_name: "Gillespie",
            prefix: "Dr.",
            suffix: "DVM",
            title: "",
            text: "",
            sortOrderOverride: 5
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 2,
            image: imgShelbyPattee,
            first_name: "Shelby",
            last_name: "Pattee",
            prefix: "",
            suffix: "",
            title: "Practice Manager",
            sortOrder: 2,
            text: "Shelby began her career as practice manager with the animal medical center shortly after Dr. Dan opened the practice. She has been in the veterinary field for the last 13 years. If you can’t find her at the clinic you can find her running around one of her 3 daughters to one of their events which are more than likely animal related!",
            sortOrderOverride: 0
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgBrittanyDianda,
            first_name: "Brittany",
            last_name: "Dianda",
            prefix: "",
            suffix: "",
            title: "Lead Veterinary Nurse",
            sortOrder: 1,
            text: "Brittany became a member of our team in early 2020 and has been a veterinary nurse for 8 years. She is the Lead Nurse at Animal Medical and she is awesome at it! She has a Pitbull named JoAnn who is her world! She says “the best part of the job is advocating and helping the animals who can’t verbalize it for themselves.”",
            sortOrderOverride: 1
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgKylieKerner,
            first_name: "Kylie",
            last_name: "Kerner",
            prefix: "",
            suffix: "",
            title: "Veterinary Nurse",
            text: "Kylie officially joined our practice in May of 2019. She is currently enrolled at the University of Idaho studying to be a Veterinarian! When she is not in school, we get to have her by our side where she works as a veterinary assistant. She also enjoys farming and working cattle in her off time, and riding her horse!",
            sortOrderOverride: 2
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgAndreaDoty,
            first_name: "Andrea",
            last_name: "Doty",
            prefix: "",
            suffix: "",
            title: "Veterinary Nurse",
            text: "Andrea became a part of our crew in 2020, She is very knowledgeable and caring about the patients she treats. Andrea has worked in this industry for many years, and we love having someone we can count on to give our patients the care they need. Andrea is also very active in the rescue community! For Andrea no dog is too big or too small to join her pack and we love her for that!",
            sortOrderOverride: 3
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgTamaraMiller,
            first_name: "Tamara",
            last_name: "Miller",
            prefix: "",
            suffix: "",
            title: "Veterinary Nurse",
            text: "Tamara is one of the originals, she became a solid member of the team back in 2016! She is well versed in anything large and small animal! Tamara is an avid Bloodhound lover but there is still room in her heart for anything equine and goat related! She is also very active in the 4-h and mustang community!",
            sortOrderOverride: 4
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgJeanineHendrickson,
            first_name: "Jeanine",
            last_name: "Hendrickson",
            prefix: "",
            suffix: "",
            title: "Veterinary Nurse",
            text: "Jeanine started with us in 2019, mainly as a small animal nurse. She found her calling when we needed to fill the spot of our equine technician and man we could not do it without her - extremely committed and knowledgeable she is capable of anything! In her “off time” she spends time with her husband and daughter around their property and taking care of an assortment of animals!",
            sortOrderOverride: 5
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgKayleeHumphries,
            first_name: "Kaylee",
            last_name: "Humphries",
            prefix: "",
            suffix: "",
            title: "Veterinary Nurse",
            text: "Kaylee joined our practice in 2019 as a kennel technician who quickly worked her way up to veterinary nurse. She has worked in the veterinary industry for the last 6+ years. She says she must thank Dr. Dan and his lovely nurses for the love and knowledge she gained from working with them in this industry. Kaylee is a wife and mother to a sweet little boy and an array of large and small animals including her canine son “Tank” - a chocolate Labrador she adores!",
            sortOrderOverride: 6
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgHeatherBrewer,
            first_name: "Heather",
            last_name: "Brewer",
            prefix: "",
            suffix: "",
            title: "Veterinary Nurse",
            text: "Heather began her journey with us in 2019. She has been a veterinary nurse for 18 years! She lives here in Emmett with her husband and two children. Her spare time is taken up with being a super mom, 4-H leader, and raising her own livestock, She also sprinkles in hunting, camping, and riding horses with her family!",
            sortOrderOverride: 7
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgCyndaSmith,
            first_name: "Cynda",
            last_name: "Smith",
            prefix: "",
            suffix: "",
            title: "Veterinary Nurse",
            text: "Cynda has been with us since 2018! She has been a veterinary nurse since she was only 20 years old! She took a couple of years off to raise her youngest daughter and is so happy to be “back in the saddle.” She feels like the veterinary field has always been her calling and she absolutely loves it. Some would say she’s darn good at it! In her spare time she raises multiple animals from horses to goats!",
            sortOrderOverride: 8
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgKelsieSanders,
            first_name: "Kelsie",
            last_name: "Sanders",
            prefix: "",
            suffix: "",
            title: "Veterinary Nurse",
            text: "Kelsie joined our practice in December of 2022. She has been in the veterinary field for over 7 years. Originally from Texas she relocated here in September of 2022, and we welcomed her to our practice shortly after. Kelsie enjoys working with all animals, even exotics. In her spare time, she loves riding her horses and working around the ranch!",
            sortOrderOverride: 9
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgKatieGoff,
            first_name: "Katie",
            last_name: "Goff",
            prefix: "",
            suffix: "",
            title: "Veterinary Nurse",
            text: "Katie joined our team in 2022 and has been a veterinary nurse since 2019. She enjoys the fast-paced environment and provides the best care to all the furry creatures! She shares her life with two horses, three dogs, a husband and a new baby!",
            sortOrderOverride: 10
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgKaitlynFox,
            first_name: "Kaitlyn",
            last_name: "Fox",
            prefix: "",
            suffix: "",
            title: "Veterinary Nurse",
            text: "Kaitlyn started in 2020 in the veterinary field as a veterinary assistant. She quickly fell in love with the profession and continued her way up to a vet nurse. She loves working with all ages of pets. She has two hounds Copper and Trigger who she absolutely loves. In her free time she likes to go hunting and explore new places with her husband Taylor.",
            sortOrderOverride: 11
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "b450410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgRachelBrown,
            first_name: "Rachel",
            last_name: "Brown",
            prefix: "",
            suffix: "",
            title: "Veterinary Nurse",
            text: "Rachel joined our team in Oct 2024, Being in the veterinary field since 2014 she knew vet med was her passion. Since she was little she always knew she wanted to work with animals! In her off time she likes to spend time with her horse and dog and make memories with her daughter and friends.",
            sortOrderOverride: 12
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "d650410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgLacyYates,
            first_name: "Lacey",
            last_name: "Yates",
            prefix: "",
            suffix: "",
            title: "Kennel Technician",
            text: "Lacy is the newest member of the team learning the ropes as to how a hospital operates! We appreciate all she does! Lacey is a track star in her spare time and an Emmett local. She enjoys any time spent with her fiancé and outdoor activities. She looks forward to growing her experience and knowledge in this industry.",
            sortOrderOverride: 0
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "d650410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgCarleeHeindel,
            first_name: "Carlee",
            last_name: "Heindel",
            prefix: "",
            suffix: "",
            title: "Kennel Attendant",
            text: "Carlee's lifelong passion for animals has led her to a career here at Animal Medical Center. Even though she is the newest member of the team, having joined us in March 2024, she's been surrounded by all species of animals for the entirety of her life and we are excited to broaden her knowledge in the veterinary field. Born and raised in Idaho, Carlee has developed a love for the outdoors, and when she isn't working you will find her on long hikes with her dogs. We are excited to have her as part of our team!",
            sortOrderOverride: 1
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "d650410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgSophiaBorg,
            first_name: "Sophia",
            last_name: "Borg",
            prefix: "",
            suffix: "",
            title: "Kennel Attendant",
            text: "Sophia joined our team towards the end of 2024, She was born and raised right here in Emmett, Idaho. She has a great love for all types of animals and even has goals of becoming a vet herself! When she is not working hard at our clinic she is working on various humanitarian projects for both animals and people in the Virgin Islands!",
            sortOrderOverride: 2
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "e650410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgLindsayGloeckner,
            first_name: "Lindsay",
            last_name: "Gloeckner",
            prefix: "",
            suffix: "",
            title: "HR Manager",
            text: "Lindsay joined our team in 2019 as a receptionist. She has worked in the animal industry for over 10 years and has even spent a fair amount of time working at a non-profit animal shelter. Her hobbies include anything outdoors and spending time with her two very active sons! She has a wide variety of animals from dogs and cats to horses and even chickens! She looks forward to giving our clients the best care we can and watching this valley grow!",
            sortOrderOverride: 0
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "e650410aa61f11ed8a30207bd355214e",
            sequence: 1,
            image: imgTaraHuett,
            first_name: "Tarrah",
            last_name: "Huett",
            prefix: "",
            suffix: "",
            title: "Receptionist",
            text: <><p>Tarrah is an Idaho local who spent her childhood growing up with her animals and taking care of them. If invited to a party she’d rather make friends with the dogs. She has 4 growing boys and an amazing man whom she’s not married to yet, but may soon be.</p><p>She also has a strange fascination for frogs.</p><p>She has a few pets: several fishes, Luke a Golden retriever and Daisy a heeler mix. She even has a turtle that roams freely around her yard!</p></>,
            sortOrderOverride: 1
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "e650410aa61f11ed8a30207bd355214e",
            sequence: 2,
            image: imgMikalaFrancke,
            first_name: "Mikala",
            last_name: "Francke",
            prefix: "",
            suffix: "",
            title: "Receptionist",
            text: "Mikala is a devoted mom and wife who passionately juggles her family life with her role as a receptionist in the clinic. When she's not at the clinic, she indulges her love for horses by training them and finding joy in their companionship and grace. Together with her family she also helps manage a cattle operation. Embracing the rich experiences that come with rural living, Mikala treasures the moments spent with her little family, where love for animals and commitment to each other thrives.",
            sortOrderOverride: 2
        },
        {
            id: "aefbe70ea62011eda8054422d5c0358d",
            staff_category_id: "e650410aa61f11ed8a30207bd355214e",
            sequence: 2,
            image: imgKourtneyWalker,
            first_name: "Kourtney",
            last_name: "Walker",
            prefix: "",
            suffix: "",
            title: "Receptionist",
            text: "My name is Kourtney, and I recently joined the AMC front desk team. I have been in the animal care industry for the past 11 years, however, and it's difficult to consider what I do work, just because it is so enjoyable working with animals everyday! I was raised in Emmett, and I adore this small town, so this is where I currently live on a small farm. I have four furry dog children of my own: American Bullies Polly and Creature, and Basset Hounds Slinky and Moo. When I am not at AMC, you can find me on my farm caring for a variety of critters like chickens, goats, pigs, and cats. I am passionate about caring for all animals, and that is why working for AMC has been such a rewarding experience so far!",
            sortOrderOverride: 3
        }
    ]
};

const teamBioData = {
    team
};

export default teamBioData;